<template>
    <div class="main-wrapper">
         <div class="title" style="position: relative;">
            <div class="titleData">{{headline.goods_name}}商品数据</div>
            <div >
                <p >(近30天数据)</p>
                 <div style="position: absolute;top: 0;right: 0;"> <el-button @click="derive" type="primary" >导出数据</el-button></div>
            </div>
           
        </div>
       
            <div class="cont">
              
              <el-scrollbar>
                    <div class="main">
                  <div>
                    <div class="main-title">
                        <div class="line"></div>
                        <div class="line">销量</div>
                        <div class="line">浏览量</div>
                        <div class="line">曝光量</div>
                        <div class="line">成交金额指数</div>
                        <div class="line">成交单量指数</div>
                        <div class="line">搜索人数</div>
                        <div class="line">访客人数</div>
                    </div>
                    <div class="main-foot" v-for="(item,index) in datalist" :key="index">
                        <div class="line">{{Number(index)+1}}号</div>
                        <div class="line"><el-input v-model="item.goods_sales_count" placeholder="请输入销量"></el-input></div>
                        <div class="line"><el-input v-model="item.goods_view_count" placeholder="请输入浏览量"></el-input></div>
                        <div class="line"><el-input v-model="item.goods_exposure_count" placeholder="请输入曝光量"></el-input></div>
                        <div class="line"><el-input v-model="item.goods_turnover_count" placeholder="请输入成交金额"></el-input></div>
                        <div class="line"><el-input v-model="item.goods_single_count" placeholder="请输入成交单量指数"></el-input></div>
                        <div class="line"><el-input v-model="item.goods_search_count" placeholder="请输入搜索人数"></el-input></div>
                        <div class="line"><el-input v-model="item.goods_visitor_count" placeholder="请输入访客人数"></el-input></div>
                    </div>
                   
                  </div>
                  </div>
               
              </el-scrollbar>
                <div class="footer-button">
                        <el-button type="primary" @click="backTo">上一步</el-button>
                        <el-button type="primary" @click="next">下一步</el-button>
                     </div>
            </div>
       
    </div>
</template>

<script>
import Vue from "vue"
import { validateGoodsData,createGoodsDataExcel } from "@/utils/apis";
export default {
    data(){
        return{
            datalist:[
                {
                    sales_count:'',//商品销量
                    view_count:'',//商品浏览量
                    exposure_count:'',//商品曝光量
                    turnover_count:'',//成交金额指数
                    single_count:'',//成交单量指数
                    search_count:'',//搜索人数
                    visitor_count:''//访客人数
                },
            ],
            commodityList:[],
            headline:'',
            sum_id:''
        }
    },
    mounted(){      
        
       
        this.getquery();
        this.$bus.$on("updateData", () => {
            this.getquery();
        })
        this.editEstablish()  
      
    },

    methods:{
        //编辑
        editEstablish(){
            if (this.$route.query.id) { 
                this.sum_id = this.$route.query.id
                let eiitList = JSON.parse(localStorage.getItem("commodityeditData"))
                this.datalist = eiitList.goods_data
            }
        },

        getquery(){
            this.datalist = JSON.parse(localStorage.getItem("thirtyData"));
            this.headline = JSON.parse(localStorage.getItem("message"))
        }, 

          // 返回上一步
        backTo() {
            this.$router.push({
                  path: "/liveStreaming/newScriptManagementData",query: { id: this.$route.query.id }
                });
        },
        //导出数据
        derive(){
              let params = {
             
              goods_data:this.datalist ,
            };
            createGoodsDataExcel(params).then((res) => {
              if (res.code == 200) {
                console.log(res)
                window.location.href = `${localStorage.getItem("hosturl")}`+res.data.src;
              }
            }).catch((err) => {
              console.error("err", err);
            });
        },

          //下一步
        next(){

          let params = {
             
              goods_data:this.datalist ,
            };
           validateGoodsData(params).then((res) => {
              if (res.code == 200) {
                localStorage.setItem("create", JSON.stringify(this.datalist));
                this.$router.push({
                    path: "/liveStreaming/newComManagementScript",query: { id: this.$route.query.id }
                });
              }
            }).catch((err) => {
              console.error("err", err);
            });
         
        },
    }
}

</script>

<style lang="scss" scoped>
      .main-wrapper {
        height: 82%;
        padding: 0 30px ;    
         .title{
            text-align: center;
            padding-bottom: 30px;
            .titleData{
                font-weight: bold; 
                width: calc(100% - 105px);
            }
        }
        .cont{
            height: 100%;
            box-sizing: border-box;
            padding: 0px 30px 0 10px;
            .el-scrollbar {
                margin: 20px;
                height: calc(100% - 120px);
            }

            ::v-deep .el-scrollbar__wrap {
                height: 100%;
                overflow-x: hidden;

                .el-scrollbar__view {
                    height: 100%;
                }
            }
                
            .main{
                margin: 0 auto;
                width: 1300px;
                height: 100%;
               .main-title{
                    flex: 1;
                    display: flex;
                    .line{
                        width: 10%;
                        text-align: center;
                        padding: 0 10px;
                    }
               }
               .main-foot{
                    flex: 1;
                    display: flex;
                    text-align: center;
                    padding-top: 20px;
                    .line{
                        display: flex;
                        display: inline-block;
                        line-height: 45px;
                        width: 10%;
                        padding: 0 10px;
                    }
                    .line:nth-child(1){
                         text-align: right;
                    }
               }
            }
            .footer-button{
                text-align: center;
                margin: 50px 0;
            }
        }
       
      }
</style>